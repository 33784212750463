import(/* webpackMode: "eager" */ "/vercel/path0/apps/platforms/app/components/umami-head/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/platforms/app/providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query-devtools/build/lib/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/filter-1/vehile-inventory.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/card/card.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/card/skeleton/skeleton.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/non-editable/core/platforms-image/platforms-image.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/about/about1/variants/01/about-variant-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/non-editable/misc/dots/dots.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/about/about1/variants/02/about-variant-02.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/about/about1/variants/03/about-variant-03.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/variants/01/footer-variant-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/logo/logo.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/variants/04/footer-variant-04.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/socials/variants/01/social-icons.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/socials/variants/03/social-icons-03.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/socials/variants/02/social-icons-02.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/menu-variants/variants/03/menu-variants-03.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/menu-variants/variants/01/menu-variants-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/store-address/variants/01/footer-store-address-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/store-address/variants/02/footer-store-address-02.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/store-address/variants/03/footer-store-address-03.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/working-hours/variants/01/footer-working-hours-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/working-hours/variants/02/footer-working-hours-02.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/working-hours/variants/03/footer-working-hours-03.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/variants/05/footer-variant-05.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/privacy-policy/privacy-policy1/privacy-policy.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/smart-widget/sw-models/variants/01/sw-models-variant-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/smart-widget/sw-category/variants/01/sw-categories-variant-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/smart-widget/sw-instagram/variants/01/sw-instagram-variant-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/stick-navbar/sticky-navbar.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-loading-skeleton/dist/skeleton.css");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/ctas/cta-premium1/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/ctas/cta1/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/floating-whatsapp/floating-whatsapp1/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/footers/footer1/variants/03/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/forms/form-contact-1/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/header/Header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/heroes/hero1/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/heroes/hero2/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/opening-hours/opening-hours1/variants/01/opening-hours-variant-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/navigation/navigation1/navigation.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/card/variants/02/card-variant-02.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/card/variants/04/card-variant-04.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/non-editable/misc/chip/chip.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/card/variants/03/card-variant-03.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/card/variants/01/card-variant-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/datalayer-form/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/details-header/variants/01/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/style.css");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/variants/02/details-variant-02.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/information/variants/01/details-information-variant-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/information/variants/02/details-information-variant-02.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/json-ld/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/optionals/variants/02/optionals-variant-02.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/optionals/variants/01/optionals-variant-01.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/send-gtm-events/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/variants/01/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/details-1/variants/02/whatsapp-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/filter-1/client-filter/filter-item/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/filter-1/client-filter/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/filter-1/client-filter/radio-item/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/filter-1/client-filter/range-item/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/filter-1/client-filter/skeleton/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/filter-1/client-pagination/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/inventory/vehicles/filter-1/vehicle-sold/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/pop-up/pop-up1/variants/01/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/premium-modules/premium-module1/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/smart-widget/sw-home/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/smart-widget/sw-makes/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/editable/stick-footer/stick-footer1/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/layout/Loading.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/layout/ThemeLogo.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/layout/ThemeToggle.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/non-editable/core/platforms-icon/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/non-editable/misc/CookieConsent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/sync-platforms/src/components/non-editable/misc/vehicle-gallery/index.tsx")